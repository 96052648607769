import { getDropdownItem, postDropdownItem, putDropdownItem, deleteDropdownItem, } from '@/api/dataentry-api-generated';
import DeleteDialog from '@/components/DeleteDialog.vue';
import SaveDialog from '@/components/SaveDialog.vue';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    name: 'DropdownItemComponent',
    components: { DeleteDialog, SaveDialog },
    props: {
        propSelected: Array,
        propCreateNew: Boolean,
        propDuplicateRecord: Boolean,
        propId: String
    },
    data: function () {
        return {
            filter: '',
            createNew: this.propCreateNew,
            duplicateRecord: this.propDuplicateRecord,
            id: this.propId,
            loading: false,
            item: {},
            tablePagination: {
                sortBy: 'screen',
                descending: true,
                rowsPerPage: 50
            },
            screenOptions: [
                { value: 'PROCESSOR', label: 'Processor' },
                { value: 'ANNUAL_ENVIRONMENT', label: 'Annual Environment' },
                { value: 'QUARTERLY_PRODUCTION', label: 'Quarterly Production' },
                { value: 'ANNUAL_PRODUCTION', label: 'Annual Production' },
                { value: 'QUARTERLY_HUMAN_RESOURCE', label: 'Quarterly HR' },
                { value: 'ANNUAL_HUMAN_RESOURCE', label: 'Annual HR' }
            ],
            fieldOptions: [
                { value: 'mainComputerOperatingSystems', label: 'Processor Screen - Main Computer Operating Systems' },
                { value: 'leaveReasons', label: 'Annual HR Screen - Reasons for Leaving' }
            ]
        };
    },
    computed: {
        uuid() {
            return this.$route.params.uuid;
        },
        buttonLabel() {
            return 'SAVE';
        },
        computedTransition() {
            return 'fade';
        }
    },
    created() {
        if (!this.createNew) {
            this.getItem(this.uuid, true);
        }
        if (this.duplicateRecord) {
            if (this.id !== undefined) {
                this.getItem(this.id, false);
            }
        }
    },
    methods: {
        async getItem(uuid, isUpdate) {
            this.loading = true;
            try {
                const result = await getDropdownItem(uuid);
                this.item = { ...result };
                if (isUpdate) {
                    this.item.screen = result.screen;
                    this.item.field = result.field;
                    this.item.dropdownItemValue = result.dropdownItemValue;
                }
                else {
                    this.item.field = '';
                    this.item.dropdownItemValue = '';
                }
                this.loading = false;
            }
            catch (error) {
                const errMsg = error;
                if (errMsg.message.includes('ResourceNotFoundException')) {
                    this.closeDialog();
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        saveItem() {
            if (!this.createNew) {
                this.updateItem();
            }
            else {
                this.createItem();
            }
        },
        async updateItem() {
            // Validate the form before proceeding
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                try {
                    await putDropdownItem(this.uuid, this.item);
                    this.$log.addMessage('Successfully updated Dropdown Item');
                    this.$router.push({ name: 'Dropdown Items' });
                }
                catch (error) {
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        async createItem() {
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                try {
                    await postDropdownItem(req);
                    this.$log.addMessage('Successfully created new Dropdown Item');
                    this.$emit('refresh');
                    this.closeDialog();
                }
                catch (error) {
                    const errorObj = error;
                    if (errorObj.message.includes('ResourceAlreadyExistsException')) {
                        const uuid = errorObj.message.substring(31, errorObj.message.length);
                        const msg = `A record with the same Dropdown Item already exists: <a href="#/DropdownItem/${uuid}">VIEW</a>`;
                        const errorMsg = {
                            type: 'warning',
                            textColor: 'black',
                            timeout: 5000,
                            position: 'top',
                            message: msg,
                            html: true
                        };
                        this.$log.addCustomMessage(errorMsg);
                    }
                    else {
                        this.$log.addError(error);
                    }
                    this.loading = false;
                }
            }
        },
        async deleteItem() {
            try {
                const result = await deleteDropdownItem(this.uuid);
                this.item = result;
                this.$log.addMessage('Successfully deleted Dropdown Item');
                this.closeDialog();
            }
            catch (error) {
                this.$log.addError(error);
            }
        },
        closeDialog() {
            if (this.createNew) {
                this.$emit('show-hide-dialog');
            }
            else {
                this.goBack();
            }
        },
        goBack() {
            this.$router.push({ name: 'Dropdown Items' });
        }
    }
});
